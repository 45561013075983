
import { mixins, Options } from 'vue-class-component'
import BaseFormDialogMixin from '@/components/common/mixins/BaseFormDialogMixin.vue'
import { Prop } from 'vue-property-decorator'
import VChart from 'vue-echarts'
import * as echarts from 'echarts/core'
import { PieChart } from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import { TooltipComponent, TitleComponent, LegendComponent } from 'echarts/components'

import { IChartData } from '@/utils/types'

echarts.use([CanvasRenderer, PieChart, TitleComponent, TooltipComponent, LegendComponent])

@Options({
  components: { VChart },
  emits: ['update:modelValue', 'updateStartSettings'],
})
export default class OpenGraphModal extends mixins(BaseFormDialogMixin) {
  @Prop()
  selectedChartRow: {
    created_at?: string
    resource_id?: string
    resource_id_count?: number
    answer_id_count?: number
    answers?: []
  } = {}

  @Prop()
  queryParams = {}

  @Prop()
  qaReports = []

  @Prop({ default: false })
  isTestMode = false

  fiftyData: IChartData[] = []
  chartData: IChartData[] = []
  componentKeydetailQAs = 0

  option = {
    color: ['#fff', '#3982C2', '#F42B73', '#3CAB38', '#8F969E', '#73c0de', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc'],
    series: [
      {
        name: 'Access From',
        type: 'pie',
        radius: [0, '30%'],
        selectedMode: 'single',
        label: {
          position: 'center',
          fontSize: 20,
        },
        labelLine: {
          show: false,
        },
        data: [{ value: 0, name: '' }],
      },
      {
        name: 'Access From',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        label: {
          fontSize: 10,
          show: true,
          position: 'outer',
          formatter: '{b}\n{c}',
        },
        emphasis: {
          label: {
            show: true,
            // fontSize: 20,
            fontWeight: 'bold',
          },
        },
        labelLine: {
          show: true,
        },
        data: this.chartData,
      },
    ],
  }

  onCancel() {
    this.visible = false
  }

  created() {
    const resource_id_count = this.selectedChartRow.resource_id_count ?? 0
    const answer_id_count = this.selectedChartRow.answer_id_count ?? 0
    const answers: {
      id: string
      name: string
      value: number
    }[] = []

    // // Loop through each qaReport
    // for (const row of this.qaReports) {
    //   // eslint-disable-next-line
    //   if (row['resource_id'] === this.selectedChartRow.resource_id) {
    //     let rowAnswers: {
    //       answer_id: string
    //       answer_name: string
    //       answer_id_count: number
    //     }[]
    //     // eslint-disable-next-line
    //     rowAnswers = row['answers']

    //     for (const col of rowAnswers) {
    //       const existingAnswer = answers.find((a) => a.id === col.answer_id)
    //       if (existingAnswer) {
    //         existingAnswer.value += col.answer_id_count
    //       } else {
    //         answers.push({
    //           id: col.answer_id,
    //           name: col.answer_name,
    //           value: col.answer_id_count,
    //         })
    //       }
    //     }
    //   }
    // }

    const rowAnswers: {
      answer_id: string
      answer_name: string
      answer_id_count: number
    }[] = this.selectedChartRow.answers ?? []
    for (const col of rowAnswers) {
      const existingAnswer = answers.find((a) => a.id === col.answer_id)
      if (existingAnswer) {
        existingAnswer.value += col.answer_id_count
      } else {
        answers.push({
          id: col.answer_id,
          name: col.answer_name,
          value: col.answer_id_count,
        })
      }
    }

    if (answers.length > 0) {
      let rate = 0
      if (resource_id_count) {
        rate = (answer_id_count / resource_id_count) * 100
      }
      const name = `回答率 ${Math.round(rate)}%`
      this.option.series[0].data = [{ value: 0, name: name }]
      this.option.series[1].data = answers
    } else {
      this.option.series = []
    }
    this.componentKeydetailQAs++
  }
}
