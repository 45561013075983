import { render } from "./OpenGraphModal.vue?vue&type=template&id=21d8858f&scoped=true"
import script from "./OpenGraphModal.vue?vue&type=script&lang=ts"
export * from "./OpenGraphModal.vue?vue&type=script&lang=ts"
script.render = render
script.__scopeId = "data-v-21d8858f"

export default script
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QPopupProxy from 'quasar/src/components/popup-proxy/QPopupProxy.js';
import QDate from 'quasar/src/components/date/QDate.js';
import QChip from 'quasar/src/components/chip/QChip.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QDialog,QCard,QCardSection,QBtn,QInput,QIcon,QPopupProxy,QDate,QChip});qInstall(script, 'directives', {ClosePopup});
