<template>
  <div class="row q-col-gutter-md q-pb-md">
    <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
      <q-card flat>
        <q-card-section>
          <div class="text-subtitle2 text-grey-8">期間選択</div>
          <div class="row no-wrap items-center date-select justify-between">
            <q-input
              outlined
              dense
              label="開始日"
              v-model="startDate"
              max="2099-12-31"
              fill-mask
              input-class="smaller-input"
              readonly
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                    <q-date v-model="startDate" mask="YYYY-MM-DD">
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="Close" color="primary" flat />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
                <q-icon name="close" @click.stop.prevent="startDate = null" class="cursor-pointer" v-if="startDate" />
              </template>
            </q-input>
            <span class="text-subtitle2 text-grey-8 q-px-sm">-</span>
            <q-input
              outlined
              dense
              label="終了日"
              v-model="endDate"
              max="2099-12-31"
              fill-mask
              input-class="smaller-input"
            >
              <template v-slot:append>
                <q-icon name="event" class="cursor-pointer">
                  <q-popup-proxy cover transition-show="scale" transition-hide="scale">
                    <q-date v-model="endDate" mask="YYYY-MM-DD">
                      <div class="row items-center justify-end">
                        <q-btn v-close-popup label="Close" color="primary" flat />
                      </div>
                    </q-date>
                  </q-popup-proxy>
                </q-icon>
                <q-icon name="close" @click.stop.prevent="endDate = null" class="cursor-pointer" v-if="endDate" />
              </template>
            </q-input>
          </div>
          <div class="row q-col-gutter-xs q-mt-xs">
            <div class="col">
              <q-btn
                outline
                dense
                size="xs"
                color="grey-6"
                label="今日"
                class="full-width"
                @click="changeDate('today')"
                :class="currentDateType === 'today' ? 'btn-save-grey' : ''"
              />
            </div>
            <div class="col">
              <q-btn
                outline
                dense
                size="xs"
                color="grey-6"
                label="昨日"
                class="full-width"
                @click="changeDate('yesterday')"
                :class="currentDateType === 'yesterday' ? 'btn-save-grey' : ''"
              />
            </div>
            <div class="col">
              <q-btn
                outline
                dense
                size="xs"
                color="grey-6"
                label="今週"
                class="full-width"
                @click="changeDate('thisWeek')"
                :class="currentDateType === 'thisWeek' ? 'btn-save-grey' : ''"
              />
            </div>
            <div class="col">
              <q-btn
                outline
                dense
                size="xs"
                color="grey-6"
                label="前週"
                class="full-width"
                @click="changeDate('lastWeek')"
                :class="currentDateType === 'lastWeek' ? 'btn-save-grey' : ''"
              />
            </div>
            <div class="col">
              <q-btn
                outline
                dense
                size="xs"
                color="grey-6"
                label="今月"
                class="full-width"
                @click="changeDate('thisMonth')"
                :class="currentDateType === 'thisMonth' ? 'btn-save-grey' : ''"
              />
            </div>
            <div class="col">
              <q-btn
                outline
                dense
                size="xs"
                color="grey-6"
                label="前月"
                class="full-width"
                @click="changeDate('lastMonth')"
                :class="currentDateType === 'lastMonth' ? 'btn-save-grey' : ''"
              />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>
    <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
      <q-card flat>
        <q-card-section>
          <div class="text-subtitle2 text-grey-8">ストーリー選択</div>
          <div class="row">
            <q-select
              outlined
              dense
              option-value="_id"
              option-label="story_name"
              multiple
              emit-value
              map-options
              v-model="selectedStories"
              :options="stories"
              label="ストーリー"
              class="full-width"
              @update:model-value="onSelectedStories"
              clearable
            />
          </div>
          <div class="row q-col-gutter-xs q-mt-xs">
            <div class="col">
              <q-btn
                outline
                dense
                size="xs"
                color="grey-6"
                label="デフォルト"
                class="full-width"
                @click="changeTriggerType('default')"
                :class="currentTriggerType === 'default' ? 'btn-save-grey' : ''"
              />
            </div>
            <div class="col">
              <q-btn
                outline
                dense
                size="xs"
                color="grey-6"
                label="ポップアップ"
                class="full-width"
                @click="changeTriggerType('popup_deliver')"
                :class="currentTriggerType === 'popup_deliver' ? 'btn-save-grey' : ''"
              />
            </div>
            <div class="col">
              <q-btn
                outline
                dense
                size="xs"
                color="grey-6"
                label="QR/URL"
                class="full-width"
                @click="changeTriggerType('qr_code')"
                :class="currentTriggerType === 'qr_code' ? 'btn-save-grey' : ''"
              />
            </div>
            <div class="col">
              <q-btn
                outline
                dense
                size="xs"
                color="grey-6"
                label="セグメント"
                class="full-width"
                @click="changeTriggerType('audience')"
                :class="currentTriggerType === 'audience' ? 'btn-save-grey' : ''"
              />
            </div>
            <div class="col">
              <q-btn
                outline
                dense
                size="xs"
                color="grey-6"
                label="一斉配信"
                class="full-width"
                @click="changeTriggerType('all')"
                :class="currentTriggerType === 'all' ? 'btn-save-grey' : ''"
              />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>
    <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3">
      <q-card flat>
        <q-card-section>
          <div class="text-subtitle2 text-grey-8">シナリオ選択</div>
          <div class="row">
            <q-select
              outlined
              dense
              option-value="value"
              option-label="label"
              multiple
              emit-value
              map-options
              v-model="selectedScenarios"
              :options="scenarios"
              label="シナリオ"
              class="full-width"
              @update:model-value="onSelectedScenarios"
              clearable
            />
          </div>
          <div class="row q-col-gutter-xs q-mt-xs">
            <div class="col">
              <q-btn
                outline
                dense
                size="xs"
                color="grey-6"
                label="初回"
                class="full-width"
                @click="changeScenarioType('initial')"
                :class="currentScenarioType === 'initial' ? 'btn-save-grey' : ''"
              />
            </div>
            <div class="col">
              <q-btn
                outline
                dense
                size="xs"
                color="grey-6"
                label="プッシュ"
                class="full-width"
                @click="changeScenarioType('push')"
                :class="currentScenarioType === 'push' ? 'btn-save-grey' : ''"
              />
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>
    <div class="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 row q-col-gutter-md">
      <div class="col">
        <q-card flat>
          <q-card-section>
            <div class="text-subtitle2 text-grey-8">ユーザー選択</div>
            <div class="row">
              <q-select
                outlined
                dense
                option-value="user_id"
                option-label="display_name"
                multiple
                emit-value
                map-options
                v-model="selectedUsers"
                :options="users"
                label="ユーザー"
                class="full-width"
                clearable
              />
            </div>
          </q-card-section>
        </q-card>
      </div>
      <div class="col">
        <q-card flat>
          <q-card-section>
            <div class="text-subtitle2 text-grey-8">集計単位選択</div>
            <q-option-group v-model="selectedUnit" :options="units" color="grey" dense />
          </q-card-section>
        </q-card>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'
import { ACTION_INITIAL, ACTION_PUSH } from '@/store/actions'
import { IInitialCampaign, IPushCampaign } from '@/utils/types'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue', 'update:queryParams'],
})
export default class ScenrioReportConditionSelector extends Vue {
  @Prop({})
  stories!: []

  @Prop({})
  users!: []

  startDate = ''
  endDate = ''
  currentDateType = ''
  selectedStories = []
  currentTriggerType = ''
  selectedScenarios = []
  currentScenarioType = ''
  selectedUnit = 'answer_unit'
  selectedUsers = []
  scenarios: {
    value: string
    label: string
  }[] = []

  units = [
    {
      label: '回答単位',
      value: 'answer_unit',
    },
    {
      label: 'ユーザー単位',
      value: 'per_user',
    },
  ]

  get queryParams() {
    return {
      start_date: this.startDate,
      end_date: this.endDate,
      stories: this.selectedStories,
      scenarios: this.selectedScenarios,
      users: this.selectedUsers,
      unit: this.selectedUnit,
      trigger_type: this.currentTriggerType,
      scenario_type: this.currentScenarioType,
    }
  }

  changeDate(type) {
    if (type === this.currentDateType) {
      this.currentDateType = ''
    } else {
      this.currentDateType = type
    }
  }

  async onSelectedStories(val) {
    this.scenarios = []
    this.selectedScenarios = []
    if (val) {
      for (const storyId of val) {
        const pushes: IPushCampaign[] = await this.$store.dispatch(ACTION_PUSH.LOAD_STORY_ITEMS, storyId)
        for (const p of pushes) {
          this.scenarios.push({
            value: p._id ?? '',
            label: p.title ?? '',
          })
        }
        const initial: IInitialCampaign = await this.$store.dispatch(ACTION_INITIAL.LOAD_STORY_ITEM, storyId)
        if (initial._id !== '') {
          this.scenarios.push({
            value: initial._id ?? '',
            label: initial.title ?? '',
          })
        }
      }
    }
  }

  onSelectedScenarios(val) {
    console.log(val, 'onSelectedScenarios')
  }

  changeTriggerType(type) {
    if (type === this.currentTriggerType) {
      this.currentTriggerType = ''
    } else {
      this.currentTriggerType = type
    }
  }

  changeScenarioType(type) {
    if (type === this.currentScenarioType) {
      this.currentScenarioType = ''
    } else {
      this.currentScenarioType = type
    }
  }

  @Watch('currentDateType')
  setDateRange() {
    const today = new Date()
    const formattedToday = today.toISOString().slice(0, 10)

    switch (this.currentDateType) {
      case 'today': {
        this.startDate = formattedToday
        this.endDate = formattedToday
        break
      }
      case 'yesterday': {
        const yesterday = new Date(today)
        yesterday.setDate(today.getDate() - 1)
        this.startDate = yesterday.toISOString().slice(0, 10)
        this.endDate = yesterday.toISOString().slice(0, 10)
        break
      }
      case 'thisWeek': {
        const currentDayOfWeek = today.getDay()
        const adjustedDayOfWeek = currentDayOfWeek === 0 ? 7 : currentDayOfWeek
        const startOfWeek = new Date(today)
        startOfWeek.setDate(today.getDate() - adjustedDayOfWeek + 1)
        const endOfWeek = new Date(today)
        endOfWeek.setDate(today.getDate() + (7 - adjustedDayOfWeek))
        this.startDate = startOfWeek.toISOString().slice(0, 10)
        this.endDate = endOfWeek.toISOString().slice(0, 10)
        break
      }
      case 'lastWeek': {
        const currentDayOfWeek = today.getDay()
        const adjustedDayOfWeek = currentDayOfWeek === 0 ? 7 : currentDayOfWeek
        const startOfPreviousWeek = new Date(today)
        startOfPreviousWeek.setDate(today.getDate() - adjustedDayOfWeek - 6)
        const endOfPreviousWeek = new Date(today)
        endOfPreviousWeek.setDate(today.getDate() - adjustedDayOfWeek)
        this.startDate = startOfPreviousWeek.toISOString().slice(0, 10)
        this.endDate = endOfPreviousWeek.toISOString().slice(0, 10)
        break
      }
      case 'thisMonth': {
        const year = today.getFullYear()
        const month = today.getMonth()
        const startOfMonth = new Date(year, month, 1)
        const endOfMonth = new Date(year, month + 1, 0)
        this.startDate = startOfMonth.toISOString().slice(0, 10)
        this.endDate = endOfMonth.toISOString().slice(0, 10)
        break
      }
      case 'lastMonth': {
        const year = today.getFullYear()
        const month = today.getMonth()
        const startOfPreviousMonth = new Date(year, month - 1, 1)
        const endOfPreviousMonth = new Date(year, month, 0)
        this.startDate = startOfPreviousMonth.toISOString().slice(0, 10)
        this.endDate = endOfPreviousMonth.toISOString().slice(0, 10)
        break
      }
      default: {
        this.startDate = ''
        this.endDate = ''
        break
      }
    }
  }

  @Watch('queryParams')
  onChangeQueryParams() {
    this.$emit('update:queryParams', this.queryParams)
  }

  created() {
    this.currentDateType = 'thisMonth'
  }
}
</script>

<style scoped lang="scss">
.q-field--with-bottom {
  padding-bottom: 0;
}
.text-subtitle2 {
  font-size: 0.75rem;
}
.q-card {
  height: 100%;
}
:deep(.q-field--outlined .q-field__control) {
  padding: 0 8px;
}
:deep(.q-select--multiple .q-field__native > span) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
:deep(.q-btn.full-width span) {
  font-size: 8px;
  line-height: 1.25rem;
  white-space: nowrap;
}
:deep(.q-radio--dense .q-radio__label) {
  font-size: 12px;
  line-height: 1.5rem;
  margin-top: 8px;
}
:deep(.smaller-input) {
  font-size: initial;
}
:deep(.date-select .q-field__append) {
  font-size: 24px;
}
</style>
